export const monthItems = [
  {
    memberLevel: 1,
    memberName: "小資用戶",
    price: 800,
    oldPrice: 1000,
    monthOrYear: "月",
    memberNameFontColor: "white",
    memberNameBgColor: "rgb(13,71,161)",
    projectQty: 5,
    productQty: 100,
    exportTemplateLimit: 3,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × "],
    imgPath: "www_24.png"
  },
  {
    memberLevel: 2,
    memberName: "採購高手",
    price: 1200,
    oldPrice: 1600,
    monthOrYear: "月",
    memberNameFontColor: "white",
    memberNameBgColor: "rgb(13,71,161)",
    projectQty: 10,
    productQty: 200,
    exportTemplateLimit: 3,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × "],
    imgPath: "www_23.png"
  },
  {
    memberLevel: 3,
    memberName: "批發專家",
    price: 1800,
    oldPrice: 2400,
    monthOrYear: "月",
    memberNameFontColor: "white",
    memberNameBgColor: "rgb(13,71,161)",
    projectQty: 30,
    productQty: 500,
    exportTemplateLimit: 3,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × "],
    imgPath: "www_22.png"
  }
];

export const yearItems = [
  {
    memberLevel: 4,
    memberName: "小資用戶",
    price: 9600,
    oldPrice: 12000,
    monthOrYear: "年",
    memberNameFontColor: "black",
    memberNameBgColor: "#FFCA28",
    projectQty: 5,
    productQty: 120,
    exportTemplateLimit: 12,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × ", "匯出特殊範本", "上傳下載專案圖片"],
    imgPath: "www_24.png"
  },
  {
    memberLevel: 5,
    memberName: "採購高手",
    price: 14400,
    oldPrice: 19200,
    monthOrYear: "年",
    memberNameFontColor: "black",
    memberNameBgColor: "#FFCA28",
    projectQty: 10,
    productQty: 250,
    exportTemplateLimit: 12,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × ", "匯出特殊範本", "上傳下載專案圖片"],
    imgPath: "www_23.png"
  },
  {
    memberLevel: 6,
    memberName: "批發專家",
    price: 21600,
    oldPrice: 28800,
    monthOrYear: "年",
    memberNameFontColor: "black",
    memberNameBgColor: "#FFCA28",
    projectQty: 30,
    productQty: 600,
    exportTemplateLimit: 12,
    plugin: ["每月批貨專案數量 × ", "每月批貨商品數量 × ", "匯出特殊範本", "上傳下載專案圖片"],
    imgPath: "www_22.png"
  }
];

export const priceList = [
  {
    level: 1,
    name: "1個月方案",
    price: 1000,
    oldPrice: 1200,
    fontColor: "white",
    bgColor: "rgb(13,71,161)"
  },
  {
    level: 3,
    name: "3個月方案",
    price: 2800,
    oldPrice: 3600,
    fontColor: "white",
    bgColor: "rgb(13,71,161)"
  },
  {
    level: 6,
    name: "6個月方案",
    price: 5400,
    oldPrice: 7200,
    fontColor: "white",
    bgColor: "rgb(13,71,161)"
  },
  {
    level: 12,
    name: "1年方案",
    price: 9999,
    oldPrice: 14400,
    fontColor: "white",
    bgColor: "rgb(13,71,161)"
  }
];
