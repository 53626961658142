<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-icon style="position:absolute;top:10px;right:10px;" @click="closeDialog">mdi-close</v-icon>
      <v-card-title>
        <strong>購買方案</strong>
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text style="padding:14px 25px;">
            <span> 請選擇您要購買的方案及輸入您的 EppBuyer 登入帳號 </span>
            <v-select
              label="購買方案"
              :items="priceList"
              item-text="name"
              item-value="level"
              v-model="columnValues.plan_type"
              style="margin-top:16px;"
            ></v-select>
            <v-text-field
              label="EppBuyer 帳號"
              placeholder="請輸入您的 EppBuyer 帳號"
              v-model.trim="columnValues.email"
              :error-messages="errorMsg.email"
              @input="errorMsg.email = ''"
              @blur="columnValues.email = columnValues.email.trim()"
              maxlength="60"
              spellcheck="false"
            ></v-text-field>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text style="padding:14px 25px;">
            <div style="margin-bottom:16px;">
              <span>我們目前僅提供匯款/轉帳的方式付款，請您匯款/轉帳到下面銀行帳號</span>
            </div>
            <v-row no-gutters align="center" justify="center" style="margin-bottom:20px;">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div style="font-weight:bold;font-size:16px;">
                  <span>銀行：渣打銀行</span>
                </div>
                <div style="font-weight:bold;font-size:16px;">
                  <span>銀行代碼：052</span>
                </div>
                <div style="font-weight:bold;font-size:16px;">
                  <span>帳號：36210053480</span>
                </div>
              </div>
            </v-row>

            <div style="margin-bottom:6px;margin-top:10px;">
              <span>※ 請確認您選擇的方案、金額及 EppBuyer 帳號</span>
            </div>

            <v-row no-gutters align="center" justify="center" style="padding:10px 4px;border:1px solid #999;border-radius:5px;">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div>
                  <span
                    >方案：<strong>{{ columnValues.plan_type == 12 ? "1年方案" : columnValues.plan_type + "個月方案" }}</strong>
                  </span>
                </div>
                <div>
                  <span
                    >金額：NTD <strong>{{ columnValues.cost }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >EppBuyer 帳號：<strong style="font-size:13px;">{{ columnValues.email }}</strong></span
                  >
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text style="padding:14px 25px;">
            <div style="margin-bottom:16px;">
              <span>請輸入您付款銀行帳號末五碼、付款金額及付款時間</span>
            </div>
            <v-row no-gutters style="width:100%">
              <v-col cols="6" style="padding-right:8px;">
                <v-text-field
                  label="* 銀行帳號末5碼"
                  placeholder="必填"
                  v-model="columnValues.last_five"
                  :error-messages="errorMsg.last_five"
                  @input="errorMsg.last_five = ''"
                  oninput="javascript: if (this.value.length > 5) this.value = this.value.slice(0, 5);"
                  maxlength="5"
                  type="number"
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="padding-left:8px;">
                <v-text-field
                  label="* 付款金額"
                  placeholder="必填"
                  v-model="columnValues.pay_money"
                  :error-messages="errorMsg.pay_money"
                  @input="errorMsg.pay_money = ''"
                  oninput="javascript: if (this.value.length > 5) this.value = this.value.slice(0, 5);"
                  maxlength="5"
                  class="inputPrice"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-menu v-model="showDateMenu" :close-on-content-click="false" :nudge-top="20" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="columnValues.pay_time"
                  :error-messages="errorMsg.pay_time"
                  @click="errorMsg.pay_time = ''"
                  label="* 付款日期"
                  placeholder="必選"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="columnValues.pay_time" @input="showDateMenu = false" locale="cn" no-title></v-date-picker>
            </v-menu>

            <div style="margin-bottom:16px;margin-top:12px;">
              <span>以下為選填欄位，您可以留下您的聯絡資訊，如對帳有誤時我們才可快速的與您取得聯繫</span>
            </div>
            <v-row no-gutters style="width:100%">
              <v-col cols="6" style="padding-right:8px;">
                <v-text-field
                  label="付款人姓名"
                  placeholder="選填"
                  v-model.trim="columnValues.payer_name"
                  oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"
                  maxlength="30"
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="padding-left:8px;">
                <v-text-field
                  label="手機號碼"
                  placeholder="選填"
                  v-model.trim="columnValues.payer_phone"
                  oninput="javascript: if (this.value.length > 15) this.value = this.value.slice(0, 15);"
                  maxlength="15"
                  class="inputPrice"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  label="其他備註"
                  placeholder="選填"
                  v-model="columnValues.payer_remarks"
                  oninput="javascript: if (this.value.length > 50) this.value = this.value.slice(0, 50);"
                  maxlength="50"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <v-card-text style="padding:14px 25px;">
            <div>
              <span>※ 請再次仔細檢查您提供的各項資訊，確認無誤後請點擊「送出」按鈕</span>
            </div>
            <v-row no-gutters style="width:100%;margin-top:30px;margin-bottom:16px;" align="center" justify="center">
              <div style="width:auto;display:inline-block !important;display:inline;">
                <div>
                  <span
                    >EppBuyer 帳號：<strong style="color:#1565C0;">{{ columnValues.email }}</strong></span
                  >
                </div>
                <div>
                  <span
                    >方案：<strong style="color:#1565C0;">{{
                      columnValues.plan_type == 12 ? "1年方案" : columnValues.plan_type + "個月方案"
                    }}</strong>
                  </span>
                </div>
                <div>
                  <span
                    >方案價格：NTD <strong style="color:#1565C0;">{{ columnValues.cost }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >銀行帳號末5碼：<strong style="color:#1565C0;">{{ columnValues.last_five }}</strong></span
                  >
                </div>
                <div>
                  <span
                    >實付金額：NTD <strong style="color:#1565C0;">{{ parseInt(columnValues.pay_money) }}</strong> 元</span
                  >
                </div>
                <div>
                  <span
                    >付款日期：<strong style="color:#1565C0;">{{ columnValues.pay_time }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payer_name != ''">
                  <span
                    >付款人：<strong style="color:#1565C0;">{{ columnValues.payer_name }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payer_name != ''">
                  <span
                    >手機號碼：<strong style="color:#1565C0;">{{ columnValues.payer_phone }}</strong></span
                  >
                </div>
                <div v-if="columnValues.payer_remarks != ''" style="max-width:340px;">
                  <span class="no_overflow"
                    >其他備註：<strong style="color:#1565C0;">{{ columnValues.payer_remarks }}</strong></span
                  >
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="5">
          <div class="pa-4 text-center">
            <v-img class="mb-4" contain height="128" src="@/assets/logo.png"></v-img>
            <h3 class="title font-weight-light mb-2">感謝您的購買</h3>
            <div style="width:100%;text-align:center;padding:0 70px;">
              <span class="caption">我們會盡快為您核對款項，確認無誤後將立即開通服務</span>
            </div>
          </div>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-btn :loading="loading" :disabled="loading" v-if="step != 1 && step != 5" depressed color="primary" @click="step--">
          上一步
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" :disabled="loading" color="primary" depressed @click="NextBtnClickHandler">
          {{ step == 5 ? "完成" : step == 4 ? "送出" : "下一步" }}
        </v-btn>
      </v-card-actions>

      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import { priceList } from "@/public_data/member_level.js";
export default {
  props: {
    show: { type: Boolean, required: true },
    itemObj: { type: Object, required: true }
  },
  watch: {
    show: function() {
      if (this.show) {
        this.columnValues.plan_type = this.itemObj.level;
        this.columnValues.plan_price = this.itemObj.oldPrice;
        this.columnValues.cost = this.itemObj.price;
        this.columnValues.pay_money = this.itemObj.price;
      } else {
        this.step = 1;
        this.columnValues = this.getColumnValues();
        this.errorMsg = this.getErrorMsg();
      }
    }
  },
  data() {
    return {
      step: 1,
      loading: false,
      columnValues: {
        email: null,
        id: null,
        plan_type: null,
        plan_price: null,
        cost: null,
        build_time: null,
        old_expiry_time: null,
        new_expiry_time: null,
        state: 0,
        pay_time: null,
        pay_money: null,
        last_five: null,
        payer_name: null,
        payer_phone: null,
        payer_remarks: null,
        checked: 0,
        remarks: null
      },
      errorMsg: { email: "", last_five: "", pay_money: "", pay_time: "" },
      showDateMenu: false,
      priceList: priceList
      // selectorItems: [
      //   { name: "1個月方案", level: 1, price: 1000 },
      //   { name: "3個月方案", level: 3, price: 2800 },
      //   { name: "6個月方案", level: 6, price: 5400 },
      //   { name: "1年方案", level: 12, price: 9999 }
      // ]
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    NextBtnClickHandler() {
      if (this.step != 5) {
        if (this.step == 1) {
          if (this.columnValues.currentCase != "") {
            if (this.accountValidation()) {
              this.step += 1;
            }
          }
        } else if (this.step == 2) {
          this.step += 1;
        } else if (this.step == 3) {
          if (this.paidValidation()) {
            this.step += 1;
          }
        } else if (this.step == 4) {
          this.updateToServer();
        }
      } else {
        this.closeDialog();
      }
    },
    accountValidation() {
      const regexEmail = /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(]?)$/;
      if (this.columnValues.email.length === 0) {
        this.errorMsg.email = "請輸入帳號";
      }
      if (this.errorMsg.email == "") {
        if (!regexEmail.test(this.columnValues.email)) {
          this.errorMsg.email = "帳號格式錯誤 (例：abc@xyz.com)";
        }
      }
      if (this.errorMsg.email != "") {
        return false;
      } else {
        return true;
      }
    },
    paidValidation() {
      if (this.columnValues.last_five == null || this.columnValues.last_five == "" || this.columnValues.last_five.length != 5) {
        this.errorMsg.last_five = "請輸入付款帳號末5碼";
      }
      // if (this.columnValues.last_five.length != 5) {
      //   this.errorMsg.last_five = "請輸入付款帳號末5碼";
      // }
      if (this.errorMsg.last_five == "") {
        if (this.columnValues.pay_money == null || this.columnValues.pay_money == "") {
          this.errorMsg.pay_money = "請輸入付款金額";
        }
      }
      if (this.errorMsg.last_five == "" && this.errorMsg.pay_money == "") {
        if (this.columnValues.pay_time == null || this.columnValues.pay_time == "") {
          this.errorMsg.pay_time = "請選擇付款日期";
        }
      }
      if (this.errorMsg.last_five == "" && this.errorMsg.pay_money == "" && this.errorMsg.pay_time == "") {
        return true;
      } else {
        return false;
      }
    },

    getColumnValues() {
      // return {
      //   currentCase: {},
      //   userAccount: "",
      //   last_five: "",
      //   pay_money: "",
      //   pay_time: "",
      //   payer_name: "",
      //   payer_phone: "",
      //   payer_remarks: ""
      // };
      return {
        email: null,
        id: null,
        plan_type: null,
        plan_price: null,
        cost: null,
        build_time: null,
        old_expiry_time: null,
        new_expiry_time: null,
        state: 0,
        pay_time: null,
        pay_money: null,
        last_five: null,
        payer_name: null,
        payer_phone: null,
        payer_remarks: null,
        checked: 0,
        remarks: null
      };
    },
    getErrorMsg() {
      return { email: "", last_five: "", pay_money: "", pay_time: "" };
    },
    async updateToServer() {
      this.loading = true;
      await this.$axios
        .post("/api/apis/order_notice_paid.php", JSON.stringify(this.columnValues))
        .then(
          function(res) {
            if (res.data == "success") {
              this.$store.commit("setMsg", {
                show: true,
                text: "送出購買方案通知成功",
                color: "#66BB6A",
                icon: "mdi-check-circle-outline",
                timeout: 3000
              });
              this.step += 1;
              this.loading = false;
            } else {
              if (res.data == "error#1") {
                this.$store.commit("setMsg", { show: true, text: "請先登入APP後再進行購買" });
              } else if (res.data == "error#3") {
                this.$store.commit("setMsg", {
                  show: true,
                  text: "已註冊帳號中沒有找到 " + this.columnValues.email + " 這個帳號，請重新確認您的 EppBuyer 帳號"
                });
                this.step = 1;
              } else if (res.data == "error#2") {
                this.$store.commit("setMsg", { show: true, text: "發生錯誤，請與我們聯繫" });
              }
              this.loading = false;
            }
          }.bind(this)
        )
        .catch(
          function(err) {
            this.$store.commit("setMsg", { show: true, text: err.getErrorMsg });
            this.loading = false;
          }.bind(this)
        );
    }
  }
};
</script>

<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.no_overflow {
  display: -webkit-box; /*作为弹性伸缩盒子模型显示*/
  -webkit-line-clamp: 1; /*显示的行数；如果要设置2行加...则设置为2*/
  line-clamp: 1;
  overflow: hidden; /*超出的文本隐藏*/
  text-overflow: ellipsis; /* 溢出用省略号*/
  -webkit-box-orient: vertical; /*伸缩盒子的子元素排列：从上到下*/
}
</style>
