<template>
  <v-row no-gutters align="center" justify="center" style="margin:70px 0 60px;width:100%;" id="priceMenuContainer">
    <v-col cols="1" sm="2" md="2"></v-col>
    <v-col cols="10" sm="8" md="8">
      <div style="font-weight: bold;font-size: 20px;margin-bottom: 10px;">購買VIP方案</div>
      <v-carousel hide-delimiter-background show-arrows interval="360000" :hide-delimiters="true" :continuous="false" height="100%">
        <v-carousel-item v-for="(item, index) in priceList" :key="index" style="width:100%;padding:0 50px">
          <v-hover v-slot:default="{ hover }">
            <v-card
              :elevation="hover ? 5 : 1"
              outlined
              :class="{ 'on-hover': hover }"
              style="cursor:pointer;border-radius: 20px;"
              @click="showDialog(item)"
              light
            >
              <v-card-text>
                <CardItem :itemObj="item"></CardItem>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col cols="1" sm="2" md="2"></v-col>
  </v-row>
</template>

<script>
import CardItem from "@/components/sub_components/PriceMenu_NewItem.vue";
import { priceList } from "@/public_data/member_level.js";

export default {
  data() {
    return {
      priceList: priceList
    };
  },
  components: {
    CardItem
  },
  methods: {
    showDialog(item) {
      this.leaveFocus();
      this.$emit("showBuyDialog", item);
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>

<style scoped></style>
