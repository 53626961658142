<template>
  <div>
    <v-card elevation="0" color="transparent" style="padding:20px">
      <v-row no-gutters style="width:100%;padding-top:10px;">
        <div style="width:100%;">
          <div>
            <span
              :style="{
                color: '#444',
                'font-weight': 'bold',
                'font-size': '24px'
              }"
              >{{ itemObj.name }}</span
            >
          </div>
        </div>
      </v-row>

      <v-divider></v-divider>

      <div style="width:100%;text-align:left;margin-top:20px;">
        <span style="color:#666;font-size:12px !important;">價格：</span> <span style="font-size:38px;color:#1976d2;">{{ itemObj.price }}</span
        ><span style="color:#666;font-size:12px !important;margin-left: 4px;">元</span>
      </div>
      <div style="width:100%;text-align:left;text-decoration:line-through; color:#FF0000;margin-bottom:6px;">
        <span style="color:#666;font-size:12px !important;">原價：{{ itemObj.oldPrice }} 元</span>
      </div>

      <v-divider></v-divider>

      <v-card-text>
        <v-row v-for="(item, index) in features" :key="index">
          <div>{{ item }}</div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    itemObj: { type: Object, required: true }
  },
  data() {
    return {
      features: ["拍照批貨建檔", "網頁後台同步編輯", "離線工作", "快速建立追加單", "自定義上架文字", "自定價格公式", "匯率換算功能"]
    };
  }
};
</script>
