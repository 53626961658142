var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"width":"100%","background":"linear-gradient(0deg, rgba(90,173,237,1) 0%, rgba(74,165,235,1) 100%)","padding-bottom":"70px"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"0","sm":"2"}}),_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': '8px',
            'margin-right': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_14.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("拍照批貨")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 批貨時直接拍照建檔，商品內容簡單點選，快速建立完整商品資訊，批貨記錄變得超輕鬆 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-right': '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_15.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("同步編輯")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 建檔後可直接上傳，其他夥伴可即時讀取及協助編輯商品資訊，作業效率瞬間提升 500% ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': '8px',
            'margin-right': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_16.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("離線工作")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 批貨時沒有網路連接? EPPBUYER 離線時一樣可以正常工作，隨時隨地保持戰鬥力 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-right': '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_17.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("追加生成")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 網頁後台提供方便的追加單建立功能，輕鬆建立轉出追加 Excel 表格，精準追加不出錯 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': '8px',
            'margin-right': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_18.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("快速上架")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 網頁後台設定您需要的格式，直接輸出上架賣場用的商品文字資訊，不需手動一遍遍輸入 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-right': '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/iconExcel.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("自定匯出")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" EPPBUYER 提供您自定義匯出的EXCEL格式，可快速與您的進銷存系統緊密合作 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': '8px',
            'margin-right': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_20.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("匯率換算")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" EppBuyer 提供匯率換算功能，批貨專案中直接設定貨幣及匯率，輕鬆解決匯率換算的麻煩 ")])])],1)],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","lg":"3"}},[_c('v-row',{style:({
            'margin-left': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-right': '8px',
            'margin-top': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'margin-bottom': _vm.$store.state.screenWidth < 600 ? '4px' : '8px',
            'border-radius': '20px',
            padding: '30px 20px',
            background: 'white',
            'box-shadow': '#999 1px 1px 5px'
          }),attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('img',{staticStyle:{"max-width":"100px"},attrs:{"src":require("@/assets/www_21.png"),"alt":"","width":"50%"}}),_c('div',{style:({ 'font-size': _vm.getTitleFontSize + 'px', color: '#555', 'font-weight': 'bold', margin: '8px 0' })},[_vm._v("價格公式")]),_c('div',{style:({ 'font-size': _vm.getContentFontSize + 'px', color: '#777', 'text-align': 'left', padding: '0 14px' })},[_vm._v(" 每次訂定價格都很頭痛？ EppBuyer 提供三種換算公式，輸入成本就搞定所有販售價格 ")])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"0","sm":"2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }